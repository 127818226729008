import * as React from 'react';

import Layout from 'components/_settings/layout';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';
import { Intro } from 'components/pages/404/intro';

const NotFoundPage = () => (
  <Layout
    pageMetadata={{
      title: 'WeGift - Page not found',
    }}
  >
    <Header />
    <Intro />
    <Footer theme="light" />
  </Layout>
);

export default NotFoundPage;
