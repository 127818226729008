import { Link } from 'gatsby';
import * as React from 'react';

import { Button } from 'components/shared/button';

import {
  Wrapper,
  Inner,
  StyledTitle,
  Description,
  TriangleImgWrapper,
  Triangle1,
  Triangle2,
} from './404-intro.styles';
import TriangleSvgUrl1 from './images/triangle-left.url.svg';
import TriangleSvgUrl2 from './images/triangle-right.url.svg';

export const Intro = () => (
  <Wrapper>
    <Inner>
      <StyledTitle as="h1">Page not found</StyledTitle>
      <Description>Sorry, the page you are looking for couldn’t be found</Description>
      <Button component={Link} theme="fill" to="/">
        Back to homepage
      </Button>
    </Inner>
    <TriangleImgWrapper>
      <Triangle1 src={TriangleSvgUrl1} />
      <Triangle2 src={TriangleSvgUrl2} />
    </TriangleImgWrapper>
  </Wrapper>
);
