import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const Triangle1 = styled.img`
  position: absolute;
  top: 256px;
  left: -21px;
  @media only screen and (min-width: 1920px) {
    left: 219px;
  }
  ${media.xs} {
    top: 416px;
  }
`;

export const Triangle2 = styled.img`
  position: absolute;
  top: -60px;
  right: -301px;
  @media only screen and (min-width: 1920px) {
    top: -33px;
    right: -35px;
  }
`;

export const TriangleImgWrapper = styled.div`
  z-index: -1;
`;

export const Description = styled.p`
  font-size: 24px;
  font-weight: normal;
  line-height: 1.33;
  color: #6b7696;
  margin-top: 10px;
  margin-bottom: 81px;
`;

export const StyledTitle = styled(Title)`
  font-size: 52px;
  font-weight: normal;
  line-height: 1.23;
  margin-bottom: 18px;
  ${media.sm} {
    font-size: 42px;
    line-height: 1.33;
  }
`;

export const Inner = styled(Container)`
  position: relative;
  text-align: center;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-top: 172px;
  padding-bottom: 242px;
  overflow: hidden;
  ${media.md} {
    padding-top: 119px;
  }
`;
